import { forwardRef } from "react";
import { useField } from "remix-validated-form";

import { ChevronDownIcon } from "~/components/svg/icons";
import { cn } from "~/utils/classnames";

import "./InputSelect.css";
import type { InputSelectProps } from "./InputSelect.types";

/**
 * Composant de selection d'une option pour les formulaires.
 */
export const InputSelect = forwardRef<any, InputSelectProps>(function ForwardedRefSelect(
  { id, label, options = [], infoMessage, errorMessage, placeholder, className, ...props },
  ref
) {
  if (ref) {
    props.ref = ref;
  }

  return (
    <div className={cn("InputSelect", className)}>
      {label ? (
        <label htmlFor={id} className="InputSelect-label">
          {label}
        </label>
      ) : null}
      <div className="InputSelect-container">
        <select
          id={id}
          className="InputSelect-field"
          defaultValue={props.defaultValue || ""}
          aria-describedby={`${props.name}-info ${props.name}-error`}
          {...props}
        >
          {placeholder ? (
            <option value="" disabled>
              {placeholder}
            </option>
          ) : null}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <ChevronDownIcon className="InputSelect-icon svgIcon" />
      </div>
      {infoMessage ? (
        <span className="Input-infoMessage" id={`${props.name}-info`}>
          {infoMessage}
        </span>
      ) : null}
      {errorMessage ? (
        <span className="InputSelect-errorMessage" id={`${props.name}-error`}>
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
});

export const ValidatedFormSelect = forwardRef<HTMLSelectElement, InputSelectProps>(
  function ValidatedForwardedRefInput(props: InputSelectProps, ref) {
    const { error, validate } = useField(props.name);

    return <InputSelect onChange={validate} errorMessage={error || ""} {...props} ref={ref} />;
  }
);
